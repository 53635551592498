import { UserAnswer } from '../../types/UserAnswer';
import { QuestionGroup } from '../../types/QuestionGroup';
import { QuestionExplain } from '../../types/QuestionExplain';

const useSetupQuestionExplains = () => {
  const setupQuestionExplains = (
    questionGroup: QuestionGroup,
    userAnswer: UserAnswer,
  ): QuestionExplain[] => {
    return questionGroup.questions.map((question, key) => {
      const answer = userAnswer.individualAnswers.find(
        (answer) => answer.questionNo === question.questionNo,
      );
      return {
        key: key,
        isCorrect: answer?.correct ?? false,
        selectedOption: answer?.selected ?? 'A',
        answerOption: question.header.answer,
        question: question,
        problem: question.problem,
        explains: question.answers.map((answer) => {
          return {
            comment: answer.comment,
            option: answer.option,
            stonePoints: answer.stonePoints,
          };
        }),
      };
    });
  };

  return { setupQuestionExplains };
};

export default useSetupQuestionExplains;
