import { Button, Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { QuestionExplain } from '../../types/QuestionExplain';
import { useTablePaginationConfig } from '../../hooks/useTablePaginationConfig';

type Props = {
  questionExplains?: QuestionExplain[];
  setQuestionExplain: (recoilState: QuestionExplain) => void;
};

const ExplainsTableSP: React.FC<Props> = ({
  questionExplains,
  setQuestionExplain,
}) => {
  const paginationConfig = useTablePaginationConfig();

  const columns: ColumnsType<QuestionExplain> = [
    {
      title: 'タイトル',
      dataIndex: ['question', 'header', 'title'],
      key: 'questionTitle',
    },
    {
      title: '結果',
      dataIndex: 'isCorrect',
      key: 'isCorrect',
      render: (isCorrect: boolean) => (
        <span>{isCorrect ? '正解' : '不正解'}</span>
      ),
    },
    {
      title: '解説はこちら',
      dataIndex: 'questionNo',
      key: 'exerciseLink',
      render: (_, questionExplain) => (
        <Link
          onClick={() => {
            setQuestionExplain(questionExplain);
          }}
          to={`/explain`}
        >
          解説
        </Link>
      ),
    },
  ];

  return (
    <StyledCard>
      <Button type="link" htmlType="button" href="/">
        問題一覧に戻る
      </Button>
      <Table
        columns={columns}
        dataSource={questionExplains}
        pagination={paginationConfig}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 25px auto;
`;

export default ExplainsTableSP;
