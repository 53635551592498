import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { questionGroupState } from '../states/questionGroupState';
import {
  questionExplainState,
  removeQuestionExplainState,
} from '../states/questionExplainsState';
import { userAnswerState } from '../states/userAnswerState';
import { QuestionExplain } from '../types/QuestionExplain';
import ExplainsTable from '../components/explains/ExplainsTable';
import ExplainsTableSP from '../components/explains/ExplainsTableSP';
import ScoreTable from '../components/explains/ScoreTable';
import ScoreTableSP from '../components/explains/ScoreTableSP';
import useSetupQuestionExplains from '../hooks/explains/useSetupQuestionExplains';
import { useMediaQueryContext } from '../components/provider/MediaQueryProvider';

const Explains = () => {
  removeQuestionExplainState();
  const { isSpSite } = useMediaQueryContext();
  const userAnswer = useRecoilValue(userAnswerState);
  const questionGroup = useRecoilValue(questionGroupState);
  const [questionExplains, setQuestionExplains] = useState<QuestionExplain[]>();
  const setQuestionExplain =
    useSetRecoilState<QuestionExplain>(questionExplainState);
  const { setupQuestionExplains } = useSetupQuestionExplains();

  useEffect(() => {
    const newQuestionExplains = setupQuestionExplains(
      questionGroup,
      userAnswer,
    );
    setQuestionExplains(newQuestionExplains);
  }, []);

  const scoreTableProps = {
    bestScore: questionGroup.bestScore,
    currentScore: userAnswer.score,
  };

  const explainsProps = {
    questionExplains,
    setQuestionExplain,
  };

  return (
    <Container>
      {isSpSite ? (
        <>
          <ScoreTableSP {...scoreTableProps} />
          <ExplainsTableSP {...explainsProps} />
        </>
      ) : (
        <>
          <ScoreTable {...scoreTableProps} />
          <ExplainsTable {...explainsProps} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 70%;
  min-width: 350px;
  margin: 3% auto;
`;

export default Explains;
